import ContextHOC from '@/components/context-hoc';
import { useTheme } from '@zenlayer/zen-design/esm/hooks/useThemeSwitch';
import 'normalize.css';
import React, { createContext, Suspense } from 'react';
import useRuntimeInfo from './api/public/useRuntimeInfo';
import Entry from './entry';
import { useLoadStaticResource } from './hooks/useLoadStaticResource';

export const GlobalContext = createContext<IGlobalContext>(
  {} as IGlobalContext
);
export let Context_gs: IGlobalContext;

const App: React.FC = (props) => {
  const [theme, themeColors] = useTheme();

  const { ready } = useRuntimeInfo();

  useLoadStaticResource(
    `/static/style/themes${
      __PRODUCTION__ ? `_${__VERSION__}` : ''
    }/${theme}/index.css`
  ); //缓存，加版本号

  return (
    <Suspense>
      {ready && (
        <ContextHOC>
          <Entry />
        </ContextHOC>
      )}
    </Suspense>
  );
};

export default App;
